.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/* GLOBAL STYLES -------------------------------------------------- */
/* Padding below the footer and lighter body text */
body {
  color: #5a5a5a;
}

/* CUSTOMIZE THE CAROUSEL -------------------------------------------------- */
/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

/* MARKETING CONTENT -------------------------------------------------- */
/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}

/* rtl:end:ignore */
/* Featurettes ------------------------- */
.featurette-divider {
  margin: 5rem 0;
  /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
}

/* rtl:end:remove */
/* RESPONSIVE CSS -------------------------------------------------- */
@media (min-width: 40em) {

  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.top1 {
  background-color: #000;
  padding: 70px 0;
  background-image: url(img/ft.png);
  background-size: cover;
}

.top1 .t1 {
  color: #fff;
  display: block;
  font-size: 30px;
  font-weight: bold;
  text-shadow: -1px 0px 6px rgba(0, 0, 0, 0.91);
}

.top1 .tp {
  color: #fff;
  text-shadow: -1px 0px 6px rgba(0, 0, 0, 0.91);
}

.buscar {
  width: 80%;
  padding: 20px;
  background-color: #fff;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

.gris {
  background-color: #2f2f2f;
  padding: 40px 0;
}

.tg1 {
  display: block;
  color: #fff;
  text-align: right;
  font-weight: 25px;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 50px 0;
}

.categoria {
  height: 200px;
  width: 90%;
  position: relative;
  background-color: #fff;
  background-size: cover;
  margin: 5%;
}

.categoria span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  padding: 5px 0;
}

.blanco {
  padding: 40px 0;
}

.t1b {
  display: block;
  color: #000;
  font-weight: bold;
  font-size: 24px;
}

.t2b {
  display: block;
  color: #000;
  font-weight: bold;
  font-size: 24px;
  float: left;
}

.t2n {
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  float: left;
}

.t1g {
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
}

.p1g {
  color: #fff;
}

.masofertas {
  float: right;
  background-color: #000;
  display: block;
  color: #fff;
  padding: 8px 20px;
  text-decoration: none;
  margin: 30px 0 20px 0;
}

.oferta {
  width: 90%;
  border: solid 1px #efefef;
  height: 100%;
  margin: 0 5% 20px 5%;
  text-decoration: none;
  display: block;
  background-color: #fff;
}

.oferta img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.ic {
  padding: 20px;
}

.tc1 {
  color: #000;
  font-size: 18px;
  display: block;
}

.pc1 {
  color: #000;
  font-size: 14px;
}

.gris2 {
  background-color: #e8e8e8;
  padding: 40px 0;
}

.pagination li a {
  color: #000;
}

.menu1 {
  background-color: #000;
  padding: 10px 0;
}

.menu1 ul {
  width: 100%;
  height: 20px;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  height: 40px;
}

.menu1 ul li {
  display: inline-block;
  color: white;
  margin-right: 10px;
}

.menu1 ul li a {
  color: #fff;
  text-decoration: none;
  padding: 0 10px;
}

.menu2 {
  background-color: #fff;
  padding: 10px 0;
}

.menu2 ul {
  width: 100%;
  height: 20px;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  height: 40px;
}

.menu2 ul li {
  display: inline-block;
  color: white;
  margin: 0 40px;
}

.menu2 ul li a {
  color: #000;
  text-decoration: none;
  padding: 15px 10px;
}

.m2c {
  color: #8571e8 !important;
  border-bottom: solid 4px #8571e8;
}

.menu3 {
  background-color: #fff;
  padding: 10px 0;
  border-bottom: solid 1px #efefef;
  margin: 0 0 30px 0;
}

.menu3 ul {
  margin: -30px 0 0 -20px;
  padding: 0;
  float: left;
}

.menu3 ul li {
  display: inline-block;
  color: white;
  margin: 0 30px;
}

.menu3 ul li a {
  color: #000;
  text-decoration: none;
  padding: 15px 10px;
}

.m3c {
  color: #8571e8 !important;
  border-bottom: solid 4px #8571e8;
}

.menu-top {
  border-right: solid 1px #ccc;
  margin-right: 30px;
  padding-right: 30px;
}

.mob {
  background-color: #fff !important;
  color: #000 !important;
}

.negro {
  background-color: #000;
  padding: 40px;
}

.convocatoria {
  border: solid 1px #efefef;
  padding: 20px;
}

.convocatoria .ct {
  overflow: hidden;
}

.convocatoria .ct .fecha span {
  color: #000;
  font-weight: bold;
  display: block;
  text-align: center;
  font-size: 24px;
}

.convocatoria .ct .fecha p {
  margin: 0;
  padding: 0;
  display: block;
  color: #000;
  text-align: center;
}

.convocatoria .ct .fecha {
  background-color: #ffc93f;
  padding: 10px;
  float: left;
  display: block;
  width: 70px;
}

.convocatoria .ct .info {
  display: block;
  margin: 0 0 0 90px;
}

.convocatoria .ct .info span {
  display: block;
  font-weight: bold;
  font-size: 18px;
  color: #000;
}

.convocatoria .ct .info p {
  margin: 0;
  padding: 0;
  color: #000;
}

.convocatoria .cb {
  margin: 10px 0 0 0;
  color: #000;
}

.convocatoria .cb span {
  display: block;
}

.convocatoria .cb p {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.elige {
  background-color: #ccc;
  padding: 40px 0;
  background-image: url(img/fondo-trama.png);
}

.ef {
  background-color: #fff;
  padding: 10px 20px;
  border: solid 1px #efefef;
}

.ef select {
  margin: 10px 0;
}

.btn-primary {
  background-color: #000;
  border: #000;
}

.cd {
  font-size: 13px;
  line-height: 16px;
  color: #999;
}

.datos {
  margin: 50px 0;
}

.datos-icon {
  font-size: 30px;
  float: left;
  margin: 10px 0 0 0;
}

.datos span {
  float: left;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin: 0 0 0 20px;
}

.datos p {
  margin: 10px 0 0 30px;
  padding: 0;
  float: left;
  color: #000;
}

.newsletter {
  float: right;
  background-color: #fff;
  width: 30%;
  padding: 30px;
  display: block;
  margin: -70px -10px 0 0;
  color: #000;
}

.clear {
  clear: both;
}

.footer {
  float: left;
  width: 65%;
}

.footer img {
  width: 160px;
  height: auto;
  float: left;
}

.footer .fd {
  float: left;
  width: 160px;
  margin: 0 0 0 40px;
}

.footer .fd span {
  color: #fff;
  font-weight: bold;
}

.footer .fd ul {
  margin: 0;
  padding: 0;
}

.footer .fd ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer .fd ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  line-height: 14px;
  margin: 20px 0;
  display: block;
}

.newsletter span {
  display: block;
  font-size: 24px;
}

.btn-danger {
  background-color: #ff285c;
  border-color: #ff285c;
}

.btn-info {
  background-color: #917cfd;
  border-color: #917cfd;
  color: #fff;
  font-weight: normal;
}

.breadcrumbs ul {
  margin: 0 0 0 -20px;
  padding: 0 0 30px 0;
}

.breadcrumbs ul li {
  list-style: none;
  float: left;
}

.breadcrumbs ul li a {
  display: block;
  color: #ff285c;
  text-decoration: none;
  margin: 0;
  padding: 0 20px;
  font-size: 13px;
  border-right: solid 1px #ccc;
}

.bc {
  color: #000 !important;
}

.txt1 {
  display: block;
  text-align: right;
  margin: -10px 0 30px 0;
}

.txt1 a {
  color: #8571e8;
}

.lm {
  color: #8571e8;
}

.form {
  background-color: #fff;
  padding: 50px;
}

.tform {
  display: block;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.pform {
  display: block;
  text-align: center;
}

.txt-centro {
  text-align: center;
}

.rojo {
  color: #dc3545;
}

.form-check-input:checked {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-link {
  color: #999;
  text-decoration: none;
}

.btn-link-2 {
  color: #000;
  text-decoration: none;
}

.iniciales {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4d5da;
  color: #f85e74;
  font-weight: bolder;
}

.filtros {
  background-color: #fff;
  padding: 20px;
  color: #000;
  min-height: 507px;
  position: relative
}

.loading_class {
  background-color: white;
  filter: alpha(opacity=50);
  /* IE */
  opacity: 0.5;
  /* Safari, Opera */
  -moz-opacity: 0.50;
  /* FireFox */
  z-index: 999999;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
}

.ft {
  border-bottom: solid 1px #ccc;
  padding: 0 0 10px 0;
  margin: 0 -20px;
}

.ft span {
  padding: 0 20px;
}

.ft2 {
  padding: 10px 0;
  border-bottom: solid 1px #ccc;
}

.ft2 span {
  display: block;
  float: left;
}

.ft2 ul {
  margin: 10px -3px;
  padding: 0;
}

.ft2 ul li {
  margin: 3px;
  padding: 3px 12px;
  list-style: none;
  background-color: #ff285c;
  color: #fff;
  float: left;
  font-size: 14px;
}

.ft2 ul li a {
  text-decoration: none;
  color: #fff;
  padding: 0 0 0 10px;
}

.ft2l {
  color: #999;
  text-decoration: none;
  display: block;
  float: right;
  font-size: 13px;
}

.ft3 {
  padding: 10px 0;
}

.ft3 .form-select {
  margin: 10px 0;
}

.rbl {
  float: left;
  color: #000;
}

.rbl span {
  display: block;
  font-size: 18px;
  font-weight: bold;
}

.rbl p {
  margin: 0;
  padding: 0;
  color: #999;
}

.rbr {
  float: right;
}

.tipoc1 {
  display: block;
  float: left;
  background-color: #917cfd;
  color: #fff;
  padding: 1px 8px;
  font-size: 14px;
}

.rc1 {
  color: #999;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.tcon {
  display: block;
  font-weight: bold;
  font-size: 18px;
  color: #000;
}

.tcon .ico {
  background-color: #e7e2fe;
  color: #917cfd;
  border: solid 1px #917cfd;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fechas {
  margin: 10px 0;
}

.fechas div {
  float: left;
}

.fechas div span {
  font-size: 13px;
}

.fechas div p {
  margin: 0;
  padding: 0;
}

.fe1 {
  padding: 0 20px 0 0;
  border-right: solid 1px #ccc;
}

.fe2 {
  padding: 0 0 0 20px;
}

.fep {
  font-size: 13px;
  display: block;
  margin: 10px 0;
  min-height: 97px;
}

.cona {
  background-color: #000;
  color: #fff;
  text-align: center;
  display: block;
  padding: 10px;
  text-decoration: none;
  margin: 20px 0 0 0;
  font-weight: normal;
}

.personal {
  background-color: #fff;
  padding: 30px;
}

#personal-menu {
  margin: 0;
  padding: 0;
}

#personal-menu li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#personal-menu li a {
  display: block;
  color: #444;
  display: block;
  text-decoration: none;
  padding: 8px 0;
  margin: 3px 0;
  max-width: 250px;
}

#personal-menu li a:hover {
  background-color: #efefef;
}

#personal-menu li .current {
  background-color: #917cfd !important;
  color: #fff !important;
}

#personal-menu li a i {
  width: 30px;
  text-align: center;
}

#personal-menu li a:hover {
  display: block;
  color: #000;
}

#personal-menu li a object {
  fill: #fff !important;
  width: 30px;
  height: auto;
  float: left;
  margin: 0 10px 0 0;
}

.icono-cambio {
  color: #fff;
  fill: #fff;
}

.msg {
  padding: 20px;
  position: relative;
}

.msg .letra {
  width: 80px;
  height: 80px;
  background-color: #efefef;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg .info1 {
  display: block;
  margin: 0 120px 0 120px;
}

.msg .info2 {
  position: absolute;
  right: 20px;
  top: 20px;
}

.convocatoria {
  border: solid 1px #ccc;
}

.convocatoria .imagen {
  float: left;
  width: 150px;
  height: 150px;
  background-color: #ffc93f;
}

.convocatoria .imagen .fecha {
  background-color: #ffc93f;
  padding: 12px;
  display: block;
  margin: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
  font-size: 30px;
}

.convocatoria .info {
  display: block;
  margin: 0 0 0 170px;
}

.convocatoria .info .cat {
  background-color: #ff285c;
  color: #fff;
  padding: 3px 15px;
}

.convocatoria .info .it1 {
  display: block;
  font-weight: bold;
  color: #000;
  padding: 20px 0;
}

.convocatoria .info ul {
  margin: 0;
  padding: 0;
}

.convocatoria .info ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 13px;
}

.convocatoria .info ul li i {
  width: 15px;
}

.convocatoria div .info2 {
  margin: 0 80px 0 0;
}

.infof {
  position: absolute;
  right: 0;
  top: 10px;
  background-color: #000;
  color: #fff;
  padding: 2px 10px;
}

.redest {
  margin: 0 0 0 40px;
}

.redest li a i {
  color: #000;
}

.regt {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.lineas {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  width: 200px;
}

.lineas .azul {
  display: block;
  height: 4px;
  background-color: #0f69b4;
  float: left;
  width: 40%;
}

.lineas .roja {
  display: block;
  height: 4px;
  background-color: #e22c2c;
  float: left;
  width: 60%;
}

.animation_input {
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.5s;
  /* Internet Explorer */
  -o-animation: fadein 0.5s;
  /* Opera < 12.1 */
  animation: fadein 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8AD;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control,
.form-select {
  padding: 10px;
}

.form-control,
.form-select {
  padding: 10px;
}

.ai-faq {
  border: solid 1px #eee;
  margin: 10px 0;
}

.ah-faq {
  border-bottom: none !important;
  border-top: solid 1px #eee;
}

.infop {
  display: block;
  overflow: hidden;
}

.infop div {
  overflow: hidden;
  padding: 10px 0;
}

.i1 {
  float: left;
  width: 330px;
  color: #999;
}

.i2 {
  display: block;
  margin: 0 0 0 210;
  color: #000;
}

.i3 {
  color: #999;
  float: left;
  width: 300px;
}

.i4 {
  color: #000;
  display: block;
  margin: 0 0 0 310px;
}

.personal {
  position: relative;
  overflow: hidden;
}

#editar {
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: red;
  color: #fff;
  height: 70px;
  width: 70px;
  padding: 20px 15px;
  display: block;
  font-size: 30px;
}

.p-mens {
  width: 500px;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 14px;
}

.p-mens a {
  color: #907cfd;
  font-weight: bold;
  text-decoration: none;
}

.i-morado {
  background-color: #E6E1FF;
  color: #917CFD;
}

.i-verde {
  background-color: #daffea;
  color: #34b269;
}

.i-rojo {
  background-color: #ffe3ea;
  color: #fe3665;
}

.i-naranjo {
  background-color: #ffeedf;
  color: #f2994a;
}

.i-azul {
  background-color: #d9e9fe;
  color: #2f80ed;
}

.f-blog label {
  text-transform: uppercase;
}

.comparte {
  float: left;
  width: 150px;
}

.cont-not {
  display: block;
  margin: 0 0 0 160px;
}

.comparte span {
  color: #917dfd;
  text-transform: uppercase;
  display: block;
}

.comparte ul {
  margin: 0 0 0 20px;
  padding: 0;
}

.comparte ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.comparte ul li a {
  display: block;
  color: #ff275d;
}

.txt-dest {
  font-weight: bold;
}

h3 {
  font-weight: bold;
  font-size: 24px;
  margin: 25px 0 15px 0;
}

body {
  color: #000;
}

.tit-3 {
  font-weight: bold;
  font-size: 16px;
  margin: 60px 0 15px 0;
  display: block;
}

.tit-4 {
  font-weight: bold;
  font-size: 20px;
  margin: 60px 0 15px 0;
  display: block;
}

.int {
  margin: 0;
  padding: 0;
}

.int li {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;
}

.int li a {
  color: #fff;
  display: block;
  padding: 2px 10px;
  text-decoration: none;
  margin: 0 10px 0 0;
}

.int-face {
  background-color: #2f80ed;
}

.int-twitter {
  background-color: #2d9cdb;
}

.int-insta {
  background-color: #f50dc2;
}

.ul-recursos {
  margin: 0;
  padding: 0;
}

.ul-recursos li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ul-recursos li a {
  border: solid 1px #ccc;
  padding: 5px;
  display: block;
  text-decoration: none;
  width: 70%;
  position: relative;
  margin: 8px 0;
}

.i-1 {
  float: left;
  font-size: 35px;
  color: #917cfd;
  margin: 5px 0 0 3px;
}

.ul-recursos li a div {
  display: block;
  margin: 0 0 0 40px;
  color: #000;
}

.ul-recursos li a div span {
  font-weight: bold;
  font-size: 16px;
}

.ul-recursos li a div p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.i-2 {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 25px;
  color: #fe295c;
}

#p-footer {
  color: #fff;
  display: block;
  margin: 0 0 0 200px;
}

#p-footer a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .g-blog {
    background-image: url(img/f2.png);
    background-position: right -60px;
    background-repeat: no-repeat;
  }

  body {
    font-size: 14px;
  }

  .ref-dest div {
    font-size: 14px;
  }

  .p-dest {
    font-size: 16px;
  }

  .t-dest {
    font-size: 30px;
    line-height: 33px;
  }

  .not-dest-img {
    height: 300px;
  }

  .comparte {
    float: none;
    width: 100%;
  }

  .cont-not {
    display: block;
    margin: 0;
  }

  .comparte {
    margin: 0 0 20px 0;
    display: block;
    overflow: hidden;
  }

  .comparte span {
    float: left;
  }

  .comparte ul li {
    float: left;
  }

  .comparte ul li a {
    margin: 0 0 0 15px;
  }

  .newsletter {
    float: none;
    width: 100%;
    padding: 30px;
    display: block;
    margin: -70px -10px 0 0;
    color: #000;
    border: solid 1px #efefef;
  }

  .footer img {
    width: 160px;
    height: auto;
    float: none;
    margin: 20px auto;
    display: block;
  }

  .footer {
    width: 100%;
  }

  #p-footer {
    margin: 0;
    text-align: center;
    font-size: 14px;
  }

  .ul-recursos li a {
    width: 100%;
  }
}

.bc-w {
  color: #fff !important;
}

.g-blog div div div span {
  color: #fff !important;
}

.g-blog p {
  color: #fff;
  font-weight: 300;
  margin: 30px 0 0 0;
}

.f-blog label {
  text-transform: uppercase;
}

.f-blog label {
  color: #fff;
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.f-blog select {
  background-color: #000;
  border: none;
  padding: 3px 50px 3px 5px;
  color: #fff;
}

.f-blog .btn {
  padding: 2px 10px;
}

.f-blog {
  margin-top: 20px;
}

.g-blog {
  background-image: url(img/f2.png);
  background-position: right -60px;
}

.not-dest {
  margin: 50px 0;
}

.not-dest-img {
  width: 95%;
  height: 500px;
  object-fit: cover;
}

.t-dest {
  font-weight: 700;
  display: block;
  font-size: 38px;
  color: #2f2f2f;
  line-height: 45px;
  margin: 0 0 30px 0;
}

.p-dest {
  color: #2f2f2f;
  font-size: 18px;
}

.info-dest {
  color: #2f2f2f;
}

.info-dest span span {
  background-color: #E8E8E8;
  padding: 3px 10px;
}

.ref-dest {
  margin: 30px 0 0 0;
}

.ref-dest img {
  float: left;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: solid 4px #917CFD;
}

.ref-dest div {
  display: block;
  margin: 0 0 0 90px;
  color: #969696;
  font-size: 18px;
}

.ref-dest div span {
  display: block;
  font-weight: 700;
  color: #2f2f2f;
}

.noticias {
  margin-bottom: 100px;
}

.t-not {
  display: block;
  float: left;
  font-weight: 700;
  font-size: 27px;
  color: #2f2f2f;
}

.b-not {
  float: right;
  width: 400px;
}

.noticia {
  margin-bottom: 30px;
}

.noticia img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.noticia .ref-not {
  display: block;
  margin: 10px 0;
  color: #969696;
}

.noticia .ref-not span {
  background-color: #47b6ff;
  color: #fff;
  padding: 3px 10px;
  margin: 0 0 0 20px;
}

.tit-not {
  font-weight: 700;
  display: block;
  font-size: 20px;
  color: #2f2f2f;
}

.p-not {
  color: #2f2f2f;
}

.icon-acc {
  width: 30px;
  height: 30px;
  margin: -10px 20px -10px -20px;
  align-items: center;
  justify-content: center;
  display: flex;
}



.link-tabla {
  display: block;
  width: 200px;
  font-size: 14px;
  text-align: right;
}

.link-tabla a {
  color: #333;
  text-decoration: none;
  margin: 0 5px;
}

.link-tabla a:hover {
  color: #ff285c;
}

.del-conv {
  position: absolute;
  right: 20px;
  top: 0;
  color: #ccc;
  text-decoration: none;
}

.img-of-1 {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.img-of-2 {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin: 20px 0 0 0;
}

.of-tit {
  display: block;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 10px 0;
}

.cat-of {
  margin: 0;
  padding: 0;
}

.cat-of li {
  list-style: none;
  float: left;
  margin: 0;
  padding: 0;
}

.cat-of li a {
  display: block;
  padding: 1px 5px;
  margin: 0 4px 4px 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  text-decoration: none;
}

.cat-of-a {
  display: block;
  padding: 1px 5px;
  margin: 0 4px 4px 0;
  background-color: #dc3545;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  text-decoration: none;
}

.p-of {
  display: block;
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #333;
}

.of-bots {
  margin: 20px 0 0 0;
  padding: 0;
}

.of-bots li {
  list-style: none;
  float: left;
  margin: 0;
  padding: 0;
}

.of-bots li a {
  display: block;
  padding: 5px 15px;
  margin: 0 10px 0 0;
  text-decoration: none;
}

.of-det {
  background-color: #ffc93f;
  color: #000;
}

.of-det:hover {
  background-color: #e0b138;
  color: #000;
}

.of-edit {
  background-color: #fafafa;
  color: #917cfd;
}

.of-del {
  background-color: #fff;
  border: solid 1px #fbfbfb;
  color: #ff285c;
}

.of-del:hover {
  background-color: #fafafa;
  ;
  border: solid 1px #fbfbfb;
  color: #ff285c;
}

.of-cont {
  background-color: #333;
  color: #fff;
}

.of-cont:hover {
  background-color: #222;
  color: #fff;
}




.fecha1 {
  background-color: #FF285C;
  color: #fff;
  margin: 0 0 0 -30px;
  padding: 5px 20px;
}

.single-ref {
  border: solid 1px #ccc;
  padding: 5px;
  margin: 20px 0 0 0;
}

.inicio {
  background-color: #8772F2;
}

.inicio .t {
  padding: 10px;
  display: block;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-bottom: solid 1px #7561d8;
}

.inicio div div div {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  padding: 5px 0;
}

.inicio div div span {
  display: block;
  font-weight: 700;
  font-size: 20px;
}

.fin {
  background-color: #E7B127;
  margin: 5px 0 0 0;
}

.fin .t {
  padding: 10px;
  display: block;
  text-align: center;
  color: #2F2F2F;
  font-weight: 700;
  border-bottom: solid 1px #daa726;
}

.fin div div div {
  color: #2F2F2F;
  text-align: center;
  padding: 5px 0;
  text-transform: uppercase;
  font-size: 13px;

}

.fin div div span {
  display: block;
  font-weight: 700;
  font-size: 20px;
}


.enlace {
  background-color: #2F80ED;
  padding: 10px 20px;
  margin: 5px 0 0 0;
  color: #fff;
}

.enlace span {
  display: block;
  font-weight: 700;
}


.single-ref a {
  background-color: #000;
  color: #fff;
  display: block;
  text-align: center;
  margin: 5px 0 0 0;
  padding: 8px;
  text-decoration: none;
}


.accordion {
  border: none important !important;
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 0px !important;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #fff;
}

.accordion-item h2 {
  border-bottom: solid 1px #ccc;
}

.accordion-button:not(.collapsed) {
  color: #000;
}

.acc-tit {
  font-weight: 700;
}

.icon-acc {
  width: 30px;
  height: 30px;
  margin: -10px 20px -10px -20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.edit1 {
  color: #ccc;
  margin: 0 0 0 10px;
}

.card-header {
  font-size: 14px;
}


.splide:not(.is-overflow) .splide__list {
  justify-content: center;
}

.splide:not(.is-overflow) .splide__slide:last-child {
  margin: 0 !important;
}

.splide:not(.is-overflow) .splide__arrows {
  display: none;
}

.paginacion-bottom {
  bottom: -2em !important
}

.arrow-left {
  left: -2em !important
}

.arrow-right {
  right: -2em !important
}

.extension-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.word-icon {
  background-color: #d7ddfa;
  color: #425bd6;
}

.pdf-icon {
  background-color: #f4d5da;
  color: #f85e74;

}

.img-icon {
  background-color: #ffebd9;
  color: #e68932;
}

.excel-icon {
  background-color: #dcfcdc;
  color: #34c934;
}

.ppt-icon {
  background-color: #ffebd9;
  color: #e68932;
}

.edit-conv {
  position: absolute;
  right: 20px;
  top: 30px;
  color: #ccc;
  text-decoration: none;
}

.bIPerfilesGuardados {
  background-image: url(img/f2.png);
  background-repeat: no-repeat;
  background-position: top right;
}

/*map*/
.map-container {
  height: 400px;
}

.sidebar {
  background-color: #2F80ED;
  color: #fff;
  padding: 6px 12px;
  border-radius: 4px;
}


.map-wrap {
  position: relative;
  width: 100%;
  height: calc(40vh - 77px);
  /* calculate height of the screen minus the heading */
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

.blue-bg-directorio {
  background-color: #d7ddfa;
  color: #425bd6;
}

.participantes {
  margin: 5px 0 30px 15px;
  padding: 0;
}

.participantes li {
  margin: 0;
  padding: 0;
  list-style: none;

}

.participantes li a {
  color: #917dfd;
  margin: 0 0 0 10px;
}

.filtros {
  background-color: #fff;
  padding: 20px;
  color: #000;
  position: relative;
}



.btn-primary {
  padding: 10px;
}



.descargar1 {
  float: right;
  margin: -30px 0 0 0;
}





.f-blog ul {
  overflow: hidden;
}



.f-blog ul li {
  list-style: none;
  float: left;
  margin: 10px;
}



.blanco-fondo {
  background-image: url(img/fondo-blanco.png);
  background-position: right -50px;
  background-repeat: no-repeat;
  background-size: 1400px;
}


.boton-ofertas{
  display: block;
  padding: 5px 15px;
  margin: 0 10px 0 0;
  text-decoration: none;
}






@media (max-width: 768px) {

  .blanco-fondo {
    background-image: url(img/fondo-blanco.png);
    background-position: right -30px;
    background-repeat: no-repeat;
    background-size: 700px;
  }



  .buscar {
    width: 100%;
    padding: 20px;
    background-color: #fff;
  }

  .buscar select {
    margin: 0 0 8px 0;
  }



  .form-control,
  .form-select {
    padding: 5px;
  }

  .btn-primary {
    font-size: 14px;
    padding: 7px;
  }



  .top1 .t1 {
    font-size: 25px;
    line-height: 25px;
    font-weight: bold;
    text-shadow: -1px 0px 6px rgba(0, 0, 0, 0.91);
    margin-bottom: 15px;
  }



  .menu-top {
    border-right: none;
    margin-right: 30px;
    padding-right: 30px;
  }



  .datos div {
    width: 100%;
    background-color: #fff;
    text-align: center;
    margin: 10px 0;
    padding: 20px;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
  }



  .datos div i {
    float: none;
    display: block;
    margin: 10px 0 0 0;
  }

  .datos span {
    float: none;
    display: block;
    margin: 0;
  }

  .datos p {
    margin: 0;
    padding: 0;
    float: none;
  }



  .convocatoria {
    margin: 10px 0;
  }



  .form {
    padding: 50px 20px;
  }





  .descargar1 {
    float: none;
    margin: 20px 0 0 0;
    overflow: hidden;
  }



  .menu3 ul li {
    display: block;
  }

  .menu3 ul {
    float: none;
  }



  .m3c {
    color: #8571e8 !important;
    border-bottom: none;
  }



  .m2c {
    border-bottom: none;
  }



  .g-blog {
    padding: 10px 0 !important;
    background-position: right -15px;
    background-size: 350px auto;
  }



  .menu1 ul {
    width: 700px;
  }



  .f-blog ul li {
    list-style: none;
    float: none;
    margin: 10px;
  }

  .f-blog .btn {
    width: 100%;
  }



  .b-not {
    float: none;
    width: 100%;
  }



  .menu2 ul {
    overflow: hidden;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .menu2 ul li {
    display: block;
    margin: 0;
  }

  .menu2 ul li a {
    text-align: left !important;
    width: 100%;
    display: block;
    padding: 5px;
  }





  .p-mens {
    width: 100%;
    margin: 10px auto;
  }



  .rbl {
    float: none;
  }



  .rbl span {
    width: 100%;
    padding: 20px 0 20px 0;
  }



  .rb {
    width: 100%;
    display: block;
    border-top: solid 1px #ccc;
    overflow: hidden;
    margin: 30px 0 0 0;
  }





  #editar {
    position: relative;
    float: right;
  }

  .accordion-body {
    margin: 10px 0 0 0;
    padding: 0;
  }

  .accordion-item h2 {
    margin: 20px 0 0 0;
  }



  .convocatoria .imagen {
    float: none;
    width: 100%;
    height: auto;
    background-color: #ffc93f;
  }



  .convocatoria .info {
    display: block;
    margin: 0;
  }

  .convocatoria div .info2 {
    margin: 0 30px 0 0;
  }

  .convocatoria .info ul {
    margin: 0 0 10px 0;
  }



  .del-conv {
    position: absolute;
    right: 20px;
    top: 0;
    color: #ccc;
    text-decoration: none;
  }



  .footer img {
    width: 120px;
    height: auto;
    float: none;
    margin: 20px 0 0 0;
    display: block;
  }



  .footer .fd {
    float: left;
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .slick-next {
    right: -5px;
  }

  .slick-prev {
    left: -5px;
  }

  .menu2 ul li {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  .menu2 ul li a {
    margin: 0 5px;
  }

  #p-footer {
    margin: 0;
    text-align: left;
    font-size: 12px;
    margin: 20px 0 0 0;
  }
}